var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collapse",attrs:{"id":"flight_details","data-parent":"#services_accordion"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 bb"},[_c('p',{staticClass:"flight_type"},[_vm._v(" ONWARD - "),_c('span',{staticClass:"float-right"},[_c('a',{staticClass:"add-more",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.manageFlightDetails('add', 'onward')}}},[_c('b',[_vm._v("ADD MORE")])])])]),(_vm.packageFlights.onward.length > 0)?_c('div',_vm._l((_vm.$v.packageFlights.onward.$each.$iter),function(item,index){return _c('Items',{key:item.orderId.$model,class:{
              bb: index != _vm.packageFlights.onward.length - 1
            },attrs:{"action":_vm.packageFlights.onward.length > 1,"destinations":_vm.destinations,"itemData":{ ...item }},on:{"onClickRemove":function($event){return _vm.manageFlightDetails('remove', 'onward', index)},"changeStops":(val) => _vm.handleChangeStops(val, _vm.packageFlights.onward[index])}})}),1):_vm._e()]),_c('div',{staticClass:"col-12 bb"},[_c('p',{staticClass:"flight_type"},[_vm._v(" INTER CITY/COUNTRY FLIGHT - "),_c('span',{staticClass:"float-right"},[_c('a',{staticClass:"add-more",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.manageFlightDetails('add', 'interconnect')}}},[_c('b',[_vm._v("ADD MORE")])])])]),(_vm.packageFlights.interconnect.length > 0)?_c('div',_vm._l((_vm.$v.packageFlights.interconnect.$each.$iter),function(item,index){return _c('Items',{key:item.orderId.$model,class:{
              bb: index != _vm.packageFlights.interconnect.length - 1
            },attrs:{"action":true,"destinations":_vm.destinations,"itemData":{ ...item }},on:{"onClickRemove":function($event){return _vm.manageFlightDetails('remove', 'interconnect', index)},"changeStops":(val) => _vm.handleChangeStops(val, _vm.packageFlights.interconnect[index])}})}),1):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"flight_type"},[_vm._v(" RETURN - "),_c('span',{staticClass:"float-right"},[_c('a',{staticClass:"add-more",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.manageFlightDetails('add', 'return')}}},[_c('b',[_vm._v("ADD MORE")])])])]),(_vm.packageFlights.return.length > 0)?_c('div',_vm._l((_vm.$v.packageFlights.return.$each.$iter),function(item,index){return _c('Items',{key:item.orderId.$model,class:{
              bb: index != _vm.packageFlights.return.length - 1
            },attrs:{"action":_vm.packageFlights.return.length > 1,"destinations":_vm.destinations,"itemData":{ ...item }},on:{"onClickRemove":function($event){return _vm.manageFlightDetails('remove', 'return', index)},"changeStops":(val) => _vm.handleChangeStops(val, _vm.packageFlights.return[index])}})}),1):_vm._e()])]),_c('p',{staticClass:"text-center mt-4"},[_c('button',{staticClass:"btn btn-submit ripple mb-0",attrs:{"disabled":_vm.$v.$invalid,"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.handleSaveData.apply(null, arguments)}}},[_vm._v("Save")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }